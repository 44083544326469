import React, { useState, useEffect } from 'react';
import { Container, Grid, Box, TextField, Button, Typography, Divider, Select, MenuItem, FormControl, InputLabel, Autocomplete } from '@mui/material';
import axios from 'axios';

import Chat from '../../components/Chat/Chat';
import RentIndexSummary from '../../components/RentIndex/RentIndexSummary';

import { API_ROOT } from '../../config/config';

const ENDPOINT_SUFFIX = '/rent-index';
//const ENDPOINT_SUFFIX = '/rent-index-ai';

const axiosInstance = axios.create({
  headers: {
    authorization: 'a01d0f1d-fce7-49b7-9363-ecf33fd3e8a0'
  }
});


const RentalIndexPage = () => {
  const [newUserMessage, setNewUserMessage] = useState('');
  const [newAssistantMessage, setNewAssistantMessage] = useState('');
  const [simulatedUserMessage, setSimulatedUserMessage] = useState('');

  const [immoObject, setImmoObject] = useState({});
  const [location, setLocation] = useState(null);
  const [rentIndex, setRentIndex] = useState(null);
  const [status, setStatus] = useState(null);

  const [feedbackInput, setFeedbackInput] = useState('');
  const [showThankYou, setShowThankYou] = useState(false); // show thank you message
  const [resetChat, setResetChat] = useState(false); // flag to reset the chat

  const [exampleValue, setExampleValue] = useState('');



  const handleExampleChange = (event) => {
    const value = event.target.value;
    setExampleValue(value);
    setSimulatedUserMessage({ text: value });
  };

  const reactToNewUserMessage = (message) => {
    setNewUserMessage(message);
  };


  // Set initial chat message for users
  useEffect(() => {
    setNewAssistantMessage({ text: 'Willkommen beim Mietspiegel Assistenten!\n\nBitte geben Sie die Adresse des Objektes ein.' });
  }, []);


  // React to new user input via the chat component
  useEffect(() => {
    if (newUserMessage === '') return;

    console.log(`New user message:`);
    console.log(newUserMessage);

    // Call the api with the user message
    const messageData = {
      message: newUserMessage.text,
      immoObject: immoObject
    }
    axiosInstance.post(API_ROOT + ENDPOINT_SUFFIX, messageData)
    .then((res) => {
      console.log(res);
      if (res.status !== 200) {
        console.log("Error when calling rent-index api:", res);
        setNewAssistantMessage({ text: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es erneut.' })
        return;
      }

      // Ensure that all required attributes are present in the return object
      const data = res.data;
      if ('status' in data === false || 'location' in data === false || 'immoObject' in data === false || 'rentIndex' in data === false) {
        console.log("Error: Invalid response from rent-index api:", data);
        setNewAssistantMessage({ text: 'Ein Fehler ist aufgetreten, da das System die Berechnung nicht vollständig durchführen konnte. Bitte versuchen Sie es erneut.' })
        return;
      }

      setImmoObject(data.immoObject);
      setLocation(data.location);
      setRentIndex(data.rentIndex);
      setStatus(data.status);

      const assistantMessage = createNextAssistantMessage(data);
      setNewAssistantMessage({ text: assistantMessage });
    })
    .catch((err) => {
      console.log("Error when calling rent-index api:", err);
      setNewAssistantMessage({ text: 'Ein Fehler ist beim der Verarbeitung der Mietspiegel API Ergebnisse aufgetreten. Bitte versuchen Sie es erneut.' })
    });

  }, [newUserMessage]);


  const createNextAssistantMessage = (data) => {
    const tmpImmoObject = data.immoObject;
    const tmpRentIndex = data.rentIndex;

    let assistantMessage = '';

    if ('address' in tmpImmoObject === false) {
      assistantMessage += `Bitte geben Sie die Adresse des Objektes ein. Wir prüfen dann, ob ein Mietspiegel verfügbar ist.`;
      return assistantMessage;
    }

    const rentIndexGeneral = tmpRentIndex?.general;
    if (rentIndexGeneral) {
      if (rentIndexGeneral.available === true) {
        let rentIndexType = 'unbekannter';
        if (rentIndexGeneral.type === 'QUALIFIED') {
          rentIndexType = 'qualifizierter';
        } else if (rentIndexGeneral.type === 'SIMPLE') {
          rentIndexType = 'einfacher';
        }
        assistantMessage += `Für die Adresse ${tmpImmoObject.address} ist ein ${rentIndexType} Mietspiegel verfügbar.`;
        assistantMessage += ` Die Kappungsgrenze liegt bei ${Math.round(rentIndexGeneral.capLimit*100)}%.`;
      }
      else if (rentIndexGeneral.available === false) {
        assistantMessage += `Für diese Adresse ist kein Mietspiegel verfügbar.`;
        return assistantMessage;
      }
      else { // null
        assistantMessage += `Bitte überprüfen Sie, ob ein Mietspiegel verfügbar ist. In der Datenbank konnte noch kein entsprechender Eintrag gefunden werden.`;
        return assistantMessage;
      }
    }
    else {
      assistantMessage += `Es ist leider ein Fehler aufgetreten. Bitte versuchen Sie es mit einer neuen Eingabe erneut.`;
      return assistantMessage;
    }

    const rentIndexResult = tmpRentIndex?.result;
    const rentIndexStatus = tmpRentIndex?.status;
    if (rentIndexStatus.valid === false) {
      assistantMessage += `\n\nEs konnte mit den eingegebenen Daten leider keine Miete berechnet werden.`;
      assistantMessage += `\n\nBegründung:\n${rentIndexStatus.invalidationReasons.map(reason => `- ${reason}`).join('\n')}`;
      return assistantMessage;
    }

    let mandatoryAttributesMissing = false;
    const mandatoryAttributes = tmpRentIndex?.attributes?.missing?.mandatory;
    if (mandatoryAttributes && mandatoryAttributes.length > 0) {
      assistantMessage += `\n\nEs fehlen noch die folgenden Pflichtangaben:\n${mandatoryAttributes.map(attr => `- ${attr.longForm}: ${attr.explanation}`).join('\n')}`;
      mandatoryAttributesMissing = true;
    }
    const optionalAttributes = tmpRentIndex?.attributes?.missing?.optional;
    if (optionalAttributes && optionalAttributes.length > 0) {
      assistantMessage += `\n\nSie können die Berechnung mit folgenden optionalen Angaben verbessern:\n${optionalAttributes.map(attr => `- ${attr.longForm}: ${attr.explanation}`).join('\n')}`;
    }
    if (mandatoryAttributesMissing) {
      return assistantMessage;
    }



    const rentIndexSteps = tmpRentIndex?.result?.steps;
    if (rentIndexSteps && rentIndexSteps.length > 0) {
      assistantMessage += `\n\nDie durchschnittliche Miete beträgt ${tmpRentIndex.result.mean.toFixed(2)} €/m² mit einer Spanne von ${tmpRentIndex.result.low.toFixed(2)} - ${tmpRentIndex.result.high.toFixed(2)} €/m².`;
      assistantMessage += `\nFür die angegebene Wohnungsgröße beträgt die gesamte Miete damit ${rentIndexResult.meanTotal.toFixed(2)} € mit einer Spanne von ${rentIndexResult.lowTotal.toFixed(2)} - ${rentIndexResult.highTotal.toFixed(2)} €.`;
      assistantMessage += `\n\nDie folgende Schritte wurden für die Berechnung durchgeführt:`;
      //assistantMessage += `\n- Die Basismiete beträgt ${tmpRentIndex.result.base.toFixed(2)} €/m².`;
      assistantMessage += `\n${rentIndexSteps.map(step => `- ${step}`).join('\n')}`;
    }

    return assistantMessage;
  }

  const createResetMessage = () => {
    setResetChat(true);

    setImmoObject({});
    setLocation(null);
    setRentIndex(null);
    setStatus(null);

    setTimeout(() => setResetChat(false), 200);
    setTimeout(() => setNewAssistantMessage({ text: "Willkommen beim Mietspiegel Assistenten!\n\nBitte geben Sie die Adresse des Objektes ein." }), 200);
  }


  const handleFeedbackInputChange = (event) => {
    setFeedbackInput(event.target.value);
  };

  
  const buildFeedbackMessage = (type, message) => {
    const data = {
      rentIndexFeedback : {
        type: type,
        message: message,
        newUserMessage: newUserMessage,
        newAssistantMessage: newAssistantMessage,
        immoObject: immoObject,
        location: location,
        rentIndex: rentIndex,
        status: status,
      }
    }

    return data;
  };

  const sendErrorExample = () => {
    const data = buildFeedbackMessage('error', feedbackInput);
    axiosInstance.post(API_ROOT + ENDPOINT_SUFFIX + '/feedback', data)
    .then((res) => {
      if (res.status !== 200) {
        console.log("Error when calling rent-index api:", res);
        return;
      } else {
        console.log("Feedback sent successfully");
      }
    })
    .catch((err) => {
      console.log("Error when calling rent-index feedback api:", err);
    });

    // Clear the text box
    setFeedbackInput('');

    // Show thank you message
    setShowThankYou(true);
    setTimeout(() => setShowThankYou(false), 3000);
  }

  const sendGoodExample = () => {
    const data = buildFeedbackMessage('success', feedbackInput);
    axiosInstance.post(API_ROOT + ENDPOINT_SUFFIX + '/feedback', data)
    .then((res) => {
      if (res.status !== 200) {
        console.log("Error when calling rent-index api:", res);
        return;
      } else {
        console.log("Feedback sent successfully");
      }
    })
    .catch((err) => {
      console.log("Error when calling rent-index feedback api:", err);
    });

    // Clear the text box
    setFeedbackInput('');

    // Show thank you message
    setShowThankYou(true);
    setTimeout(() => setShowThankYou(false), 3000);
  }

  const sortByLabel = (list) => {
    return list.sort((a, b) => {
      // Handle empty label case
      if (a.label === '') return -1;
      if (b.label === '') return 1;
      
      // Compare labels alphabetically
      return a.label.localeCompare(b.label, 'de', { sensitivity: 'base' });
    });
  }
  const sortedList = sortByLabel([
    { label: '', value: "" },
    { label: 'Kiel', value: "Am Dorfplatz 14 in Kiel. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Lübeck', value: "Mühlenbrücke 14 in Lübeck. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Hamburg', value: "Friedrich-Ebert-Straße 22 in Hamburg. Wohnung mit 83.4 m² und Baujahr ist 1999." },
    { label: 'Salzgitter', value: "Hauptstraße 1 in Salzgitter. Wohnung mit 80 m² und Baujahr ist 1975." },
    { label: 'Wolfsburg', value: "Roseggerstr 3 in Wolfsburg. Wohnung mit 80 m² und Baujahr ist 1975." },
    { label: 'Wolfenbüttel', value: "Leinder Straße 10 in Wolfenbüttel. Wohnung mit 80 m² und Baujahr ist 1975." },
    { label: 'Hannover', value: "Teststraße 1 in Hannover. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Hildesheim', value: "Rosenhagen 1 in Hildesheim. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Hansestadt Lüneburg', value: "Ochtmisser Kirchsteig 60 in Hansestadt Lüneburg. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Oldenburg', value: "Teststraße 1 in Oldenburg. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Osnabrück', value: "Schützenstraße 5, 76327 Osnabrück. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Bremen', value: "Parkallee 117 in Bremen. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Bremerhaven', value: "Hinrich-Schmalfeldt-Straße 4 in Bremerhaven. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Düsseldorf', value: "Teststraße 1 in Düsseldorf. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Krefeld', value: "Teststraße 1 in Krefeld. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Oberhausen', value: "Teststraße 1 in Oberhausen. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Remscheid', value: "Paul-Krause-Str. 7 in Remscheid. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Solingen', value: "Teststraße 1 in Solingen. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Neuss', value: "Teststraße 1 in Neuss. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Bergisch Gladbach', value: "Teststraße 1 in Bergisch Gladbach. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Bottrop', value: "Hauptstraße 1 in Bottrop. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Recklinghausen', value: "Mulvanyring 1 in Recklinghausen. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Bielefeld', value: "Teststraße 1 in Bielefeld. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Gütersloh', value: "Teststraße 1 in Gütersloh. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Rheda-Wiedenbrück', value: "Teststraße 1 in Rheda-Wiedenbrück. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Hamm', value: "Teststraße 1 in Hamm. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Herne', value: "Hauptstraße 1 in Herne. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Siegen', value: "Hofbachstraße 45, 57078 Siegen. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Offenbach am Main', value: "Biberstraße 7, 83098 Offenbach am Main. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Koblenz', value: "Hauptstraße 1 in Koblenz. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Trier', value: "Von-Reiche-Straße 33 in Trier. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Heilbronn', value: "Leonhardstraße 4/1 in Heilbronn. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Heidelberg', value: "Teststraße 1 in Heidelberg. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Pforzheim', value: "Hauptstraße 1 in Pforzheim. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Reutlingen', value: "Teststraße 1 in Reutlingen. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Ulm', value: "Donaustraße 5/1 in Ulm. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Ingolstadt', value: "Zanderweg 5 in Ingolstadt. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Regensburg', value: "Adolf-Münzer-Straße 14 in Regensburg. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Erlangen', value: "Walter-Flex-Straße 10 in Erlangen. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Fürth', value: " Poppenreuther Str. 3 in Fürth. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Nürnberg', value: "Teststraße 1 in Nürnberg. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Würzburg', value: "Versbacher Röthe 66 in Würzburg. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Neu-Ulm', value: "Tiefenbach 2 in Neu-Ulm. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Saarbrücken', value: "Aschbachring 11 in Saarbrücken. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Berlin', value: "Aachener Str. 1 in Berlin, 45 qm und Baujahr 1976" },
    { label: 'Potsdam', value: "Teststraße 1 in Potsdam. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Rostock', value: "Neuer Weg 1 in Rostock. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Magdeburg', value: "Am Vogelgesang 12 in Magdeburg. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Erfurt', value: "Teststraße 1 in Erfurt. Wohnung mit 80 m² und Baujahr 1975." },
    { label: 'Jena', value: "Teststraße 1 in Jena. Wohnung mit 80 m² und Baujahr 1975." },

    { label: 'Ludwigsburg', value: "Haldenstraße 29 in Ludwigsburg. 95 qm, Baujahr 1950. Die Wohnung liegt im fünften Stock und es gibt einen Aufzug." },
    { label: 'Mannheim', value: "Abendröte 25 in Mannheim. 68,3 qm, Baujahr 1980." },
    { label: 'Ludwigshafen', value: "Schumannstraße 5 in Ludwigshafen. 40 qm und Baujahr 1990." },
    { label: 'Aachen', value: "Freunder Landstraße 119 in Aachen. 70 qm und Baujahr 1923." },
    { label: 'Offenburg', value: "Am Gerberbach 2 in Offenburg. 53 qm und Baujahr 1913." },

    { label: '[AI] Kaiserslautern', value: "Mannheimer Str. 139, 67657 Kaiserslautern. 80 qm und Baujahr 1975." },
    { label: '[AI] Esslingen am Neckar', value: "Rathausplatz 2. 73728 Esslingen am Neckar , 80 m² und Baujahr 1975. Im Bad gibt es eine einfache Sanitärausstattung. Es wurden die folgenden vier Modernisierungsmaßnahmen durchgeführt (alle ab 2023): Fußboden, Innentüren, Außenwanddämmung und Spielflächen" },
    { label: '[AI] Wiesbaden', value: "Hauptstraße 1 in Wiesbaden. 100 qm und Baujahr 2022. Die Wohnung hat einen schlechten Grundriss. Dafür hat sie eine hochwertige Einbauküche und ein super Bad mit Badewanne und Dusche sowie ein Zweitbad. Der Fußboden ist hochwertiger Parkett." },
    { label: '[AI] Mönchengladbach', value: "Weiherstraße 21, 41061 Mönchengladbach. 100 qm und Baujahr 2022. " },
    { label: '[AI] Chemnitz', value: "Hauptstraße 1 in Chemnitz. 100 qm und Baujahr 1950. Es gibt eine Lüftung im Bad, aber kein Fenster. Das Bad ist gefliest. In der Küche gibt es eine Einbauküche. Bodenbelag ist durchgängig Parkett. Außerdem ist die Wohnung barrierefrei. In der Umgebung gibt es starken Lärm trotz der offenen Bebauung." },
    { label: '[AI] Mörs', value: "Bonifatiusstraße 72 47441 Moers mit 40 m² und Baujahr 1934." },
    { label: '[AI] Hanau', value: "Grüner Weg 3 in Hanau mit 40 m² und Baujahr 1950. Die Wohnung hat einen schlechten Grundriss. Dafür hat sie eine hochwertige Einbauküche und ein super Bad mit Badewanne und Dusche sowie ein Zweitbad. Der Fußboden ist hochwertiger Parkett." },
    { label: '[AI] Schwerin', value: "Mecklenburgstraße 10 in Schwerin, 80 m² und Baujahr 1975. Im Bad gibt es eine einfache Sanitärausstattung. Es wurden die folgenden vier Modernisierungsmaßnahmen durchgeführt (alle ab 2023): Fußboden, Innentüren, Außenwanddämmung und Spielflächen" },
    { label: '[AI] Gera', value: "Weg der Freundschaft 4 07546 Gera. Wohnung mit 43 m² und Baujahr 1945. Normale Ausstattung." },
    { label: '[AI] Düren', value: "Wohnung. Kaiserplatz 2 in Düren, Baujahr 1978, 75 m² Wohnfläche, ruhiges Wohnviertel am Stadtrand in der Nähe eines kleinen Parks, renoviertes Badezimmer mit Dusche, zentrale Gasheizung vor 5 Jahren erneuert, isolierverglaste Fenster, kleine Einbauküche, Balkon mit Blick ins Grüne, gut gepflegt, vor 3 Jahren teilweise renoviert mit neuem Bodenbelag und frisch gestrichen." },
    { label: '[AI] Iserlohn', value: "Schillerplatz 7 58636 Iserlohn. 72 qm und Baujahr 1943." },
    { label: '[AI] Lüdenscheid', value: "Rathausplatz 2 58507 Lüdenscheid. 72 qm und Baujahr 1943." },
    { label: '[AI] Menden', value: "Neumarkt 5, 58706 Menden. 72 qm und Baujahr 1943." },
    { label: '[AI] Schwerte', value: "Rathausstraße 31 58239 Schwerte. 72 qm und Baujahr 1943." },

    { label: '[AI] Hemer', value: "Rathaus Hademareplatz 44 58675 Hemer. 75 qm und Baujahr 1970." },
    { label: '[AI] Meschede', value: "Franz-Stahlmecke-Platz 2 59872 Meschede. 75 qm und Baujahr 1970." },
    { label: '[AI] Sundern (Sauerland)', value: "Rathausplatz 1 59846 Sundern. 75 qm und Baujahr 1970." },
    { label: '[AI] Brilon', value: "Bahnhofstraße 32. 59929 Brilon. 75 qm und Baujahr 1970." },
    { label: '[AI] Plettenberg', value: "Grünestraße 12 58840 Plettenberg. 75 qm und Baujahr 1970." },
    { label: '[AI] Schmallenberg', value: "Unterm Werth 1. 57392 Schmallenberg. 75 qm und Baujahr 1970." },
    { label: '[AI] Meinerzhagen', value: "Bahnhofstr. 9, 58540 Meinerzhagen. 75 qm und Baujahr 1970." },
    { label: '[AI] Marsberg', value: "Rathaus Lillers-Straße 8, 34431 Marsberg. 75 qm und Baujahr 1970." },
    { label: '[AI] Werdohl', value: "Goethestraße 51 58791 Werdohl. 75 qm und Baujahr 1970." },
    { label: '[AI] Altena', value: "Lüdenscheider Straße 22 58762 Altena. 75 qm und Baujahr 1970." },
    { label: '[AI] Kierspe', value: "Springerweg 21 58566 Kierspe. 75 qm und Baujahr 1970." },
    { label: '[AI] Halver', value: "Thomasstraße 18 58553 Halver. 75 qm und Baujahr 1970." },
    { label: '[AI] Olsberg', value: "Hüttenstraße 38. 59939 Olsberg. 75 qm und Baujahr 1970." },
    { label: '[AI] Winterberg', value: "Am Waltenberg 69 59955 Winterberg. 75 qm und Baujahr 1970." },
    { label: '[AI] Neuenrade', value: "Alte Burg 1 58809 Neuenrade. 75 qm und Baujahr 1970." },
    { label: '[AI] Balve', value: "Widukindplatz 1 58802 Balve. 75 qm und Baujahr 1970." },
    { label: '[AI] Bestwig', value: "Rathausplatz 1. 59909 Bestwig. 75 qm und Baujahr 1970." },
    { label: '[AI] Schalksmühle', value: "Rathausplatz 1, 58579 Schalksmühle. 75 qm und Baujahr 1970." },
    { label: '[AI] Eslohe (Sauerland)', value: "Schultheißstraße 2 59889 Eslohe. 75 qm und Baujahr 1970." },
    { label: '[AI] Medebach', value: "Österstr. 1 59964 Medebach. 75 qm und Baujahr 1970." },
    { label: '[AI] Herscheid', value: "Plettenberger Straße 27, 58849 Herscheid. 75 qm und Baujahr 1970." },
    { label: '[AI] Nachrodt-Wiblingwerde', value: "Hagener Straße 76 58769 Nachrodt-Wiblingwerde. 75 qm und Baujahr 1970." },
    { label: '[AI] Hallenberg', value: "Rathausplatz 1. 59969 Hallenberg. 75 qm und Baujahr 1970." },

    { label: '[AI] Ratingen', value: "Minoritenstr. 2, 40878 Ratingen. 75 qm und Baujahr 1970." },
    { label: '[AI] Münster', value: "Klemensstraße 10 48143 Münster, 80 m² und Baujahr 1980." },
    { label: '[AI] Neubrandenburg', value: "Friedländer Weg 4 in 17033 Neubrandenburg. Baujahr 2013 und 53,5 m². Es gibt einen Verbrauchsausweis mit 105 kWh/m²a. Die Wohnung ist barrierefrei und es gibt einen abschließbaren Fahrradkeller. Dusche und Badewanne gibt es beides. Und es gibt hochwertigen Designfußboden." },
    { label: '[AI] Karlsruhe', value: "Mombertstraße 10, 76131 Karlsruhe. 100 m² und Baujahr 1970." },
  ]);


  return (
    <Container>

      {/* Spacer */}
      <Box mt={4} />

      {/*
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={12}>
          <Typography variant="h4">Mietspiegel Assistent</Typography>
        </Grid>
      </Grid>
      */}


      {/* Spacer */}
      <Box mt={4} />


      <Grid container spacing={2} alignItems="center">

        <Grid item xs={12} sm={12}>
          <Chat newAssistantMessage={newAssistantMessage} onNewUserMessage={reactToNewUserMessage} simulatedUserMessage={simulatedUserMessage} resetChat={resetChat} />
        </Grid>

        {/*
        <Grid item xs={12} sm={4} container direction="column" alignItems="center" justifyContent="center">
          <RentIndexSummary immoObject={immoObject} />
        </Grid>
        */}

      </Grid>


      <Box mt={4} />
      <Divider 
        sx={{ 
          borderBottomWidth: 2,
          borderColor: 'primary.main',
          my: 4  // Adds margin top and bottom
        }} 
      /> 
      <Box mt={4} />


      <Grid container item xs={12} direction="row" spacing={2}>
        <Grid item xs={12}>
          <Autocomplete
            id="example-select"
            options={sortedList}
            value={exampleValue}
            onChange={(event, newValue) => {
              setExampleValue(newValue ? newValue.value : '');
              setSimulatedUserMessage({ text: newValue ? newValue.value : '' });
            }}
            renderInput={(params) => <TextField {...params} label="Beispieladresse auswählen" />}
            isOptionEqualToValue={(option, value) => option.value === value}
            fullWidth
          />
        </Grid>
      </Grid>

      <Box mt={2} />

      <Grid container item xs={12} direction="row" spacing={2}>
        <Grid item>
          <Button
            variant="contained"
            color="primary"
            onClick={createResetMessage}
          >
            Reset
          </Button>
        </Grid>
      </Grid>

      {/* Spacer */}
      <Box mt={4} />
      <Divider 
        sx={{ 
          borderBottomWidth: 2,
          borderColor: 'primary.main',
          my: 4  // Adds margin top and bottom
        }} 
      />
      <Box mt={4} />


      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={8}>
          <Typography variant="h6">Feedback</Typography>
        </Grid>
      </Grid>

      <Grid container spacing={2} alignItems="center">
        <Grid item xs={12} sm={8}>
          <Box
            sx={{
              border: '1px solid rgba(0, 0, 0, 0.23)',
              borderRadius: '4px 0 0 4px',
              padding: '14px',
              height: '100%',
            }}
            >
            <textarea
              style={{
                width: '100%',
                height: 'auto',
                border: 'none',
                outline: 'none',
                resize: 'none',
                fontFamily: 'inherit',
                fontSize: 'inherit',
                overflowY: 'auto',
              }}
              rows={1}
              value={feedbackInput}
              onChange={handleFeedbackInputChange}
            />
          </Box>
          {/* I had to remove this due to a bug in the TextField component. In case it gets fixed, reactive it.
          <TextField
          sx={{
            '& .MuiOutlinedInput-root': {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            },
          }}
          variant="outlined"
          fullWidth
          multiline
          rows={1}
          value={feedbackInput}
          onChange={handleFeedbackInputChange}
          />
          */}
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant="contained"
            color="error"
            onClick={sendErrorExample}
          >Fehler melden
          </Button>
        </Grid>
        <Grid item xs={12} sm={2}>
          <Button
            fullWidth
            variant="contained"
            color="success"
            onClick={sendGoodExample}
          >Bestätigen
          </Button>
        </Grid>
      </Grid>

      {showThankYou && (
        <Grid item xs={12} sm={12}>
          <Box mt={3} />
          <Typography variant="h4" sx={{ transition: 'opacity 1s', opacity: showThankYou ? 1 : 0, color: 'green', textAlign: 'center', display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>Vielen Dank für das Feedback!</Typography>
        </Grid>
      )}


      {/* Spacer */}
      <Box mt={8} />

    </Container>
  );
};

export default RentalIndexPage;
