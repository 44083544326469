import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import M from 'materialize-css';
import axios from 'axios';
import { API_ROOT, AXIOS_CONFIG } from '../../config/config';
import withUser from './withUser';

const Register = ({ user, loginUser }) => {
  const [submitClicked, setSubmitClicked] = useState(false);
  const [callFailed, setCallFailed] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [consent, setConsent] = useState(false);
  const [errors, setErrors] = useState({
    count: null,
    email: null,
    password: null,
    consent: null,
  });

  const navigate = useNavigate();

  useEffect(() => {
    // Ensure that label does not overlap with input when prefilled
    M.updateTextFields();

    // Redirect away from register page if user is already logged in
    if (user) {
      navigate('/');
    }
  }, [user, navigate]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setSubmitClicked(true);
    setCallFailed(false);

    const currentErrors = validateInputs({ email, password, consent });
    setErrors(currentErrors);

    if (currentErrors.count === 0) {
      try {
        const res = await axios.post(
          `${API_ROOT}/user/register`,
          { email, password, consent },
          AXIOS_CONFIG
        );
        if (res) {
          loginUser(res.data.user);
          navigate('/');
        }
      } catch (err) {
        // In case we encountered an error because the user is already registered,
        // try to log the user in.
        try {
          const loginRes = await axios.post(
            `${API_ROOT}/user/login`,
            { email, password },
            AXIOS_CONFIG
          );
          if (loginRes) {
            loginUser(loginRes.data.user);
            navigate('/');
          }
        } catch (error) {
          console.log(error);
          setCallFailed(true);
        }
      }
    }
  };

  const handleChange = (e) => {
    const { id, value, checked } = e.target;
    if (id === 'consent') {
      setConsent(checked);
    } else if (id === 'email') {
      setEmail(value.trim());
    } else if (id === 'password') {
      setPassword(value);
    }

    const newErrors = validateInputs({ email, password, consent });
    setErrors(newErrors);
  };

  const validateInputs = (state) => {
    const errors = { count: 0, email: null, password: null, consent: null };

    if ('email' in state) {
      const emailPattern = new RegExp(
        /^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i
      );
      if (!emailPattern.test(state.email)) {
        errors.email = true;
        ++errors.count;
      }
    }

    if ('password' in state) {
      if (state.password.length === 0) {
        errors.password = true;
        ++errors.count;
      }
    }

    if ('consent' in state) {
      if (!state.consent) {
        errors.consent = true;
        errors.count += 1;
      }
    } else {
      errors.consent = true;
      errors.count += 1;
    }

    return errors;
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="input-field">
          <label htmlFor="email">E-Mail</label>
          <input
            id="email"
            name="email"
            type="text"
            value={email}
            onChange={handleChange}
          />
          {submitClicked && errors.email ? (
            <span className="helper-text" style={{ color: 'red' }}>
              Bitte überprüfen Sie Ihre E-Mail Adresse.
            </span>
          ) : null}
        </div>

        <div className="input-field">
          <label htmlFor="password">Passwort</label>
          <input
            id="password"
            name="password"
            type="password"
            value={password}
            onChange={handleChange}
          />
          {submitClicked && errors.password ? (
            <span className="helper-text" style={{ color: 'red' }}>
              Bitte überprüfen Sie Ihr Passwort.
            </span>
          ) : null}
        </div>

        <div>
          <p>
            <label>
              <input
                id="consent"
                type="checkbox"
                checked={consent}
                onChange={handleChange}
              />
              <span>
                Ich akzeptiere die{' '}
                <Link to="/datenschutz" target="_blank">
                  Datenschutzerklärung
                </Link>{' '}
                und{' '}
                <Link to="/agb" target="_blank">
                  AGB
                </Link>
                .
              </span>
            </label>
            {submitClicked && errors.consent ? (
              <div>
                <span className="helper-text" style={{ color: 'red' }}>
                  Bitte akzeptieren Sie die Datenschutzerklärung und AGB.
                </span>
              </div>
            ) : null}
          </p>
        </div>

        <div className="center">
          {submitClicked && callFailed ? (
            <div style={{ paddingBottom: '20px' }}>
              <span
                className="helper-text"
                style={{ color: 'red', paddingTop: '10px', paddingBottom: '10px' }}
              >
                Es gab einen Fehler bei der Registrierung. Bitte versuchen Sie es
                noch einmal.
              </span>
            </div>
          ) : null}
        </div>

        <div className="center">
          <button className="btn-large blue darken-1">
            Kostenlos registrieren
          </button>
        </div>
      </form>
    </div>
  );
};

export default withUser(Register);
