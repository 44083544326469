// src/components/Chat/Chat.js
import React, { useState, useRef, useEffect } from 'react';
import { Box, TextField, Button, List, ListItem, ListItemText, keyframes } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import SendIcon from '@mui/icons-material/Send';
import ResizeObserver from 'resize-observer-polyfill';



const Chat = ({ newAssistantMessage, onNewUserMessage, simulatedUserMessage, resetChat }) => {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [waitForResponse, setWaitForResponse] = useState(true); // disable chat input while waiting for response
  const chatEndRef = useRef(null);
  const userInputRef = useRef(null);
  const buttonRef = useRef(null);

  const handleSendMessage = () => {
    if (userInput.trim()) {
      const newMessage = { text: userInput, id: messages.length, sender: 'user' };
      setMessages([...messages, newMessage]);
      setUserInput('');
      onNewUserMessage(newMessage);
      setWaitForResponse(true);
    }
  };

  // Set the focus to the chat input field at the beginning and
  // whenever the input is empty (after message is sent).
  useEffect(() => {
    if (userInput === '') {
      userInputRef.current.focus();
    }
  }, [userInput]);

  // Process messages that are passed from the parent to the chat component.
  // The messages are interpreted as assistant messages.
  useEffect(() => {
    if (!newAssistantMessage) return; // ignore empty messages
    const newMessage = { ...newAssistantMessage, id: messages.length, role: 'assistant' }
    setMessages([...messages, newMessage]);
    setWaitForResponse(false);
  }, [newAssistantMessage]);


  // Process simulated messages of users. These make debugging easier as
  // a given input will be set in the input window.
  useEffect(() => {
    if (!simulatedUserMessage) return; // ignore empty messages
    setUserInput(simulatedUserMessage.text);
  }, [simulatedUserMessage]);


  // Reset the chat when the flag is set
  useEffect(() => {
    if (resetChat === true) {
      setMessages([]);
      setUserInput('');
    }
    resetChat = false;
  }, [resetChat]);


  const handleUserInputChange = (event) => {
    setUserInput(event.target.value);
  };


  const scrollToBottom = () => {
    chatEndRef.current?.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
  };


  useEffect(() => {
    scrollToBottom();
  }, [messages]);


  // ResizeObserver due to issues when resizing the chat component
  useEffect(() => {
    const ro = new ResizeObserver((entries) => {
      try {
        for (let entry of entries) {
          // Handle resize logic
        }
      } catch (err) {
        console.log('ResizeObserver loop error', err);
      }
    });

    if (chatEndRef.current) {
      ro.observe(chatEndRef.current);
    }

    return () => {
      if (chatEndRef.current) {
        ro.unobserve(chatEndRef.current);
      }
    };
  }, []);


  // Define the pulsing keyframes for the send button while
  // in waiting state
  const pulse = keyframes`
    0% {
      transform: rotate(-45deg) scale(1);
    }
    50% {
      transform: rotate(-45deg) scale(1.4);
    }
    100% {
      transform: rotate(-45deg) scale(1);
    }
  `;


  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '80vh' }}>
      <Box sx={{ flexGrow: 1, overflowY: 'auto', padding: 2, border: '1px solid #ddd', borderRadius: '4px' }}>
        <List>
          {messages.map((message) => (
            <ListItem key={message.id} sx={{ justifyContent: message.sender === 'user' ? 'flex-end' : 'flex-start' }}>
              <ListItemText
                primary={<span style={{ whiteSpace: 'pre-wrap' }}>{message.text}</span>}
                sx={{
                  backgroundColor: message.sender === 'user' ? '#e0e0e0' : '#1976d2',
                  color: message.sender === 'user' ? 'black' : 'white',
                  borderRadius: '10px',
                  padding: '10px',
                  maxWidth: '75%',
                  whiteSpace: 'pre-wrap'
                }}
              />
            </ListItem>
          ))}
          <ListItem ref={chatEndRef} />
        </List>
      </Box>

      <Box mt={2} sx={{ display: 'flex' }}>
        <TextField
          inputRef={userInputRef}
          sx={{
            '& .MuiOutlinedInput-root': {
              borderTopRightRadius: 0,
              borderBottomRightRadius: 0,
            },
          }}
          variant="outlined"
          fullWidth
          multiline
          rows={1}
          value={userInput}
          onChange={handleUserInputChange}
          onKeyPress={(event) => {
            if (event.key === 'Enter' && !event.shiftKey) {
              if (waitForResponse === false) {
                event.preventDefault();  // Prevent default Enter behavior
                handleSendMessage();
              }
            }
          }}
        />
      <Button
        variant="contained"
        ref={buttonRef}
        color="primary"
        onClick={handleSendMessage}
        disabled={waitForResponse}
        sx={{
          borderTopLeftRadius: 0,
          borderBottomLeftRadius: 0,
          ...(waitForResponse && { // define style while in waiting state
            backgroundColor: '#e0e0e0',
            '& .MuiSvgIcon-root': {
              animation: `${pulse} 1.5s infinite ease-in-out`,
            },
          }),
        }}
      >
        <SendIcon sx={{ transform: 'rotate(-45deg)' }} />
      </Button>
      </Box>
    </Box>
  );
};

export default Chat;
